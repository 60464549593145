import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useComponentDidMount, useVisibleInOverflow } from '@hcs/hooks';

import styles from './CommentsList.module.css';

type CommentsListItemProps = {
  item: string;
  pageIndex: number;
  itemIndex: number;
  onItemOverflow?: (nextPageStartIndex: number) => void;
};

export type CommentsListProps = {
  list: string[];
  pageIndex: number;
  onItemOverflow?: (nextPageStartIndex: number) => void;
};

export const CommentsList = ({
  list = [],
  pageIndex,
  onItemOverflow,
}: CommentsListProps) => {
  return (
    <>
      {list.map((item, idx) => {
        return item.length === 0 ? (
          <br />
        ) : (
          <CommentsListItem
            key={idx}
            item={item}
            pageIndex={pageIndex}
            itemIndex={idx}
            onItemOverflow={onItemOverflow}
          />
        );
      })}
    </>
  );
};

const CommentsListItem = ({
  item,
  itemIndex,
  onItemOverflow,
}: CommentsListItemProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const { visibleFull } = useVisibleInOverflow(listRef);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useComponentDidMount(() => setIsMounted(true));
  useEffect(() => {
    if (!visibleFull && isMounted) {
      onItemOverflow?.(itemIndex);
    }
  }, [visibleFull, isMounted, itemIndex, onItemOverflow]);

  return (
    <div
      className={classNames(styles.Container, {
        // An item that is overflowing is set to display on the next page
        // so we need to hide it on the current page
        [styles.hidden]: !visibleFull && isMounted,
      })}
      ref={listRef}
    >
      {item}
    </div>
  );
};
