import React, { useCallback, useState } from 'react';

import { Section } from '@hcs/pdf/pdf-service';

import { CommentsList } from '../../features/Comments';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { PropertyReportPage } from '../PropertyReports/PropertyReportPage';

interface Props {
  pageIndex: number;
  commentsList: string[];
}

const dataHcName = 'pexp-subject-comments-page';

const CommentsPage = ({ pageIndex, commentsList }: Props) => {
  const [nextPageStartItemIndex, setNextPageStartItemIndex] = useState<
    number | null
  >(null);

  const onItemOverflow = useCallback(
    (itemIndex: number) => {
      if (
        nextPageStartItemIndex === null ||
        (itemIndex > 0 && itemIndex < nextPageStartItemIndex)
      ) {
        setNextPageStartItemIndex(itemIndex);
      }
    },
    [nextPageStartItemIndex]
  );

  return (
    <>
      <PropertyReportPage dataHcName={dataHcName}>
        <Section
          sectionHeaderProps={{
            title: `Comments${pageIndex > 0 ? ' Continued' : ''}`,
          }}
          dataHcName={'subject-comments-section'}
        >
          <CommentsList
            list={commentsList}
            pageIndex={pageIndex}
            onItemOverflow={onItemOverflow}
          />
        </Section>
      </PropertyReportPage>
      {nextPageStartItemIndex !== null && (
        <CommentsPage
          pageIndex={pageIndex + 1}
          commentsList={commentsList.slice(nextPageStartItemIndex)}
        />
      )}
    </>
  );
};
export const PexpSubjectCommentsPages = () => {
  const subjectDoc = useSubjectDocument();

  if (!subjectDoc) {
    throw new Error('Missing Subject Document');
  }

  if (!subjectDoc.data.comments) return null;

  const commentsList = subjectDoc.data.comments.split('\n');
  return <CommentsPage pageIndex={0} commentsList={commentsList} />;
};
